.login-page-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .login-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    input {
      background-color: #fff;
    }
    button {
      min-width: 100%;
      margin-top: 20px;
    }
  }
  .alert-wrap {
    position: absolute;
    top: 30px;
  }
}
