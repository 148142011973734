@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;700;900&display=swap");

:root {
  --color-blue-dark: #2f8bcc;
  --color-blue-light: #92b0d2;
  --color-grey: #d7d7d7;
  --color-red-light: #dc9094;
  --color-red-dark: #d13e56;
}

.page-container {
  padding: 50px;
  min-height: 100vh;
}

@media (max-width: 500px) {
  .page-container {
    padding-inline: 20px !important;
  }
}
