.unauthorized-container {
  min-height: 100vh;
  min-width: 100vw;
  font-size: calc(18px + 3.8vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > a {
    text-decoration: none;
    button {
      font-size: calc(13px + 1.8vw);
      cursor: pointer;
    }
  }
}
